import React from 'react'
import { Helmet } from 'react-helmet'
import '../styles/index.scss'
import useSiteMetadata from './SiteMetadata'

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link rel="apple-touch-icon" href="/img/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/img/android-chrome-192x192.png" />
        <link rel="icon" type="image/png" sizes="512x512" href="/img/android-chrome-512x512.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/img/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/img/favicon-16x16.png" />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="https://www.juliamanacas.com/img/android-chrome-512x512.png" />
        <meta property="og:url" content="https://www.juliamanacas.com/" />
      </Helmet>
      {children}
    </div>
  )
}

export default TemplateWrapper
